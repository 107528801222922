<template>
  <div>
    <div class=" px-12 py-8 max-w-5xl mx-auto">
      <div class=" text-center text-2xl mb-4">
        添加收款人
      </div>
      <a-steps :current="current">
      <a-step v-for="item in steps" :key="item.title" :title="item.title" />
    </a-steps>
    </div>
    <div class="px-12 max-w-2xl mx-auto bg-white">
      <router-view @update:current="handleCurrentUpdate"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  created () {
    console.log(this.$route.fullPath)
  },
  data () {
    return {
      current: 0,
      steps: [
        {
          title: '收款人信息'
        },
        {
          title: '银行账户详情'
        },
        {
          title: '检查'
        },
        {
          title: '完成'
        }
      ]
    }
  },
  methods: {
    handleCurrentUpdate (value) {
      this.current = value
    }
  }
}
</script>

<style scoped>

</style>
